<template>
    <div id="template-contact" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex-1">
            <div class="title flex uppercase justify-center">Email from contact website</div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <vs-table multiple v-model="selected" @selected="handleSelected" pagination max-items="10" search
                :data="message">
                <template slot="thead">
                    <vs-th class="w-50">Pilih semua <vs-button color="primary" type="border" icon-pack="feather"
                            icon="icon-trash" v-if="showdelete" class="ml-3" @click="openConfirm"></vs-button>
                    </vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :state="tr.status == 1 ? 'success' : 'default'" :data="tr" :key="indextr"
                        v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].email">
                            <span class="mr-3">{{ truncString(data[indextr].full_name, 20) }} - </span>
                            <span class="grey">{{ truncString(data[indextr].message, 50) }}</span> <br>
                            <span class="primary">{{ data[indextr].created_at }}</span>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selected: [],
                message: [],
                showdelete: false,
                form: {
                    id: ''
                }
            }
        },
        mounted() {
            this.loadData()
        },
        watch: {
            selected: function (newVal) {
                if (newVal.length > 0) {
                    this.form.id = newVal.map(x => x.id).join()
                    this.showdelete = true
                } else {
                    this.showdelete = false
                }
            }
        },
        methods: {
            async loadData() {
                this.getDataMessage().then(resp => {
                    this.message = resp
                })
            },
            handleSelected(tr) {
                this.$router.push('/detail-contact/' + tr.id)
            },
            truncString(str, max, add) {
                add = add || '.';
                return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
            },
            getDataMessage() {
                return new Promise((resolve, reject) => {
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/contact',
                            method: 'GET',
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            deleteMessage() {
                this.actionDelete().then(() => {
                        this.$vs.notify({
                            title: 'Success!',
                            text: 'Deleted!',
                            color: 'success',
                            position: 'top-center'
                        })
                        window.location.reload(true)
                    })
                    .catch(err => {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: err.response ? err.response.data.message : 'Something error, ' + err,
                            color: 'danger',
                            position: 'top-center'
                        })
                        this.$vs.loading.close()
                    })

            },
            actionDelete() {
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.form.id
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/contact',
                            method: 'DELETE',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            openConfirm() {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: `Delete`,
                    text: 'Are you sure?click `delete` to delete data.',
                    accept: this.deleteMessage,
                    acceptText: 'Delete'
                })
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template-contact {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .primary {
                color: $primary;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>